export const FirebaseConfig = {
	"projectId": "oimotoboyv2",
	"appId": "1:86719464890:web:eacb11fcb900dae18e4983",
	"databaseURL": "https://oimotoboyv2-default-rtdb.firebaseio.com",
	"storageBucket": "oimotoboyv2.appspot.com",
	"locationId": "southamerica-east1",
	"apiKey": "AIzaSyAy2eH1BBKYtCcf50gnJcSKp_3CqHxlApc",
	"authDomain": "oimotoboyv2.firebaseapp.com",
	"messagingSenderId": "86719464890",
	"measurementId": "G-VRP5D6DYCY"
};